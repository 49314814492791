import { DocsFilter } from '../gastronomicDailyCart/DocsFilter';
import { RouteInstitutionMembers } from './RouteInstitutionMembers';
import entities from './entities.json';

let {
  institutions
} = entities;


export default function ({ setPermissions }) {
  return {
    name: 'Institutión',
    slug: 'institution',

    permissions: [
      setPermissions({ slug: 'institutions', label: 'Instituciones', type: 'coded', actionsToAdd: ['group:approbe'] }),
    ],

    entities: {
      institutions
    },

    routesAdmin: {
      'institution': {
        'groupApprobe': {
          permission: { resource: 'institutions', action: 'group:approbe' },
          Content: RouteInstitutionMembers
        }
      }
    },
    
    components: {
      'institutions': {
        crud: {
          ListHeaderExtraActions: DocsFilter
        }
      },
    }
  };
};