import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import ModalAlert from './ui/ModalAlert';
import SectionCrudForm from './SectionCrudForm';
import { FormField } from '../components/Form';
import { useModule } from "../context/ModuleContext";
import { withRouter } from 'react-router-dom';
import { IonButton, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';


function SectionCrudModel(props) {
  let {
    history,
    refresh,
    model, // ModelClass constructor
    entitySlug,
    editStyle, // onsite modal route
    reorder,
    title,
    // show
    showToast = true,
    showDeleted = false,
    showBtnAdd = true,
    showBtnUpdate = true,
    showBtnDelete = true,
    showBtnMove = true,
    // validatiion
    fieldsRequired,
    onValidation = (() => null),
    // callbacks 
    fetchItems = null,
    sortDocs = null,
    handleBeforeSave = (() => null),
    onDelete = (() => null),
    onFetch = (() => null),
    navigateTo = (() => null),
    // UI
    ExtraActions,
    listStyle,
    ListItem,
    ItemExtraActions,
    ListBtns = (() => null),
    FormSection = null,
    FormInputFields = null,
    // labels
    addButtonPermissionAction = 'create',
    addButtonLabel = '+ Agregar',
    // Classes
    classNameFormSection = ''
  } = props;

  const [docs, setDocs] = useState([]);
  const [docsListed, setDocsListed] = useState([]);
  const docsPerPage = 30;
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);
  const { isAllowed } = useModule();
  // List
  fetchItems = fetchItems || (async () => {
    let docs = await model.getAll();
    if (!showDeleted) {
      docs = docs.filter(doc => !doc.data?.deleted);
    }
    // TODO: hacer configurable el fieldName desde SectionCrudModel y SectionCrudObject
    if (reorder) {
      model.sortDocs(docs, 'sort');
    } else {
      if (sortDocs) {
        docs = sortDocs(docs);
      } else {
        model.sortDocs(docs, 'createdAt DESC', 'dateString');
      }
    }
    setDocs(docs);
    onFetch && onFetch(docs);
  });
  ListItem = ListItem || (({ doc }) => (<>
    <span className="">{doc?.data?.name}</span>
  </>));

  const nextPage = () => {
    const start = docsListed.length;
    const end = start + docsPerPage;
    setDocsListed(prevDocs => [
      ...prevDocs,
      ...docs.slice(start, end)
    ]);
  };

  // Form
  FormSection = FormSection || SectionCrudForm;
  FormInputFields = FormInputFields || (() => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
  </>));

  useEffect(() => {
    fetchItems(setDocs);
  }, [refresh]);

  useEffect(() => {
    if (docs) {
      setDocsListed([]);
      nextPage();
    }
  }, [docs]);

  const refreshList = async () => {
    await fetchItems(setDocs);
  };

  const handleSave = async (doc) => {
    if (reorder) {
      doc.sort = (doc.sort >= 0) ? doc.sort : docs.length;
    }
    handleBeforeSave(doc);
    await model.createOrUpdate(doc);
    refreshList();
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      history.push(navigateTo(doc));
    }
  };

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedDoc(doc);
  };

  const confirmedDelete = async () => {
    onDelete(selectedDoc);
    await selectedDoc.delete();
    refreshList();
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
    showToast && toast.error('Se ha eliminado el documento');
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
  };

  const handleAdd = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setSelectedDoc({});
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      setSelectedDoc({});
      history.push(navigateTo());
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(docs);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    if (reorder) {
      setDocs(newOrder);
      await model.saveSort(newOrder);
      showToast && toast.success('Se ha actualizado el orden');
    }
  };

  return (
    <div className="section-crud-model">
      <div className="flex flex-row place-content-between items-center place-items-center">
        {title ? (
          <h1 className="text-xl font-semibold">{title}</h1>
        ) : ''}
        {(isAllowed(entitySlug, [addButtonPermissionAction]) && showBtnAdd) ? (
          <IonButton
            size="small"
            fill="solid"
            color="primary"
            onClick={handleAdd}
          >
            {addButtonLabel}
          </IonButton>
        ) : null}
        {ExtraActions ? (
          <ExtraActions {...{refreshList, docs, docsListed, setDocs, setDocsListed}} />
        ) : null}
      </div>
      {openSelector && selectedDoc && !selectedDoc.id && (
        <div className={`my-5 ${classNameFormSection}`}>
          {/* form add */}
          <FormSection
            model={model}
            editStyle={editStyle}
            doc={selectedDoc}
            onClose={() => {
              setOpenSelector(false);
              setSelectedDoc(null);
            }}
            onSave={handleSave}
            fieldsRequired={fieldsRequired}
            onValidation={onValidation}
            FormInputFields={FormInputFields}
            showToast={showToast}
          />
        </div>
      )}
      {docsListed?.length ? (<>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="docs">
            {(provided) => (
              <ul className={`grid grid-cols-1 gap-2 ${docsListed && docsListed.length ? 'my-5' : ''}`} {...provided.droppableProps} ref={provided.innerRef}>
                {docsListed.map((doc, index) => (
                  <Draggable key={doc.id} draggableId={doc.id} index={index}>
                    {(provided) => (
                      <div id={doc.id} ref={provided.innerRef} {...provided.draggableProps}>
                        {/* form edit */}
                        {openSelector && selectedDoc && selectedDoc.id === doc.id ? (
                          <div id={doc.id} className={`mb-3 ${classNameFormSection}`}>
                            <FormSection
                              model={model}
                              editStyle={editStyle}
                              doc={selectedDoc}
                              onClose={() => {
                                setOpenSelector(false);
                                setSelectedDoc(null);
                              }}
                              onSave={handleSave}
                              fieldsRequired={fieldsRequired}
                              onValidation={onValidation}
                              FormInputFields={FormInputFields}
                              showToast={showToast}
                            />
                          </div>
                        ) : (
                          <li className="bg-white rounded px-2 py-2 border border-gray-300">
                            <div className="flex place-content-stretch mb-2">
                              <ListItem doc={doc} style={listStyle }/>
                            </div>
                            <div className='relative w-full'>
                              <ListBtns doc={doc} />
                              {(isAllowed(entitySlug, ['update']) && showBtnUpdate) ? (
                                <IonButton color="medium" size="small" fill="clear" onClick={() => handleEdit(doc)}>
                                  Editar
                                </IonButton>
                              ) : null}
                              {(isAllowed(entitySlug, ['delete']) && showBtnDelete) ? (
                                <IonButton color="medium" size="small" fill="clear" onClick={() => handleDelete(doc)}>
                                  Eliminar
                                </IonButton>
                              ) : null}
                              {(reorder && isAllowed(entitySlug, ['update']) && showBtnMove) ? (
                                <IonButton color="medium" size="small" fill="clear" {...provided.dragHandleProps}>
                                  Mover
                                </IonButton>
                              ) : null}
                              {ItemExtraActions ? (<ItemExtraActions item={doc} isAllowed={isAllowed} />) : null}
                            </div>
                          </li>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </>) : null}

      {docs?.length ? (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            nextPage();
            setTimeout(() => ev.target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      ) : null}

      {showDeleteConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas eliminar este elemento?"
          onConfirm={confirmedDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
}

export default withRouter(SectionCrudModel);
