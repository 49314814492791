import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Model from '../../libs/ModelClass';
import config from '../../config';
import dayjs from 'dayjs';
import { chunkArray, getImageURL, priceFormat } from '../../libs/utils';
import { IonButton, IonIcon, IonPopover, IonList, IonItem, IonLabel } from '@ionic/react';
import { 
  receiptOutline,
  restaurant
} from 'ionicons/icons';
import { nanoid } from 'nanoid';


const CartsModel = Model.extend(config.modules?.cart?.cartsEntitySlug);
const ItemModel = Model.extend(config.modules.cart.cartItemEntitySlug);

// Devuelve un rango de días laborales alrededor de una fecha específica
const getDaysRange = (dayDate, daysBefore, daysAfter, showToday = true) => {
  const centerDate = dayjs(dayDate); // Convierte dayDate a una instancia de dayjs
  const dates = [];

  // Agregar fechas anteriores a dayDate
  let daysAddedBefore = 0;
  let i = 1;
  while (daysAddedBefore < daysBefore) {
    const date = centerDate.subtract(i, 'day');
    const dayOfWeek = date.day();
    
    // Si no es sábado (6) ni domingo (0), se agrega al arreglo
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      dates.unshift(date.format('YYYY-MM-DD')); // Usar unshift para mantener el orden
      daysAddedBefore++;
    }
    i++;
  }

  // Agregar la fecha central (si es un día laboral)
  const centerDayOfWeek = centerDate.day();
  if (showToday && centerDayOfWeek !== 0 && centerDayOfWeek !== 6) {
    dates.push(centerDate.format('YYYY-MM-DD'));
  }

  // Agregar fechas posteriores a dayDate
  let daysAddedAfter = 0;
  i = 1;
  while (daysAddedAfter < daysAfter) {
    const date = centerDate.add(i, 'day');
    const dayOfWeek = date.day();

    // Si no es sábado (6) ni domingo (0), se agrega al arreglo
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      dates.push(date.format('YYYY-MM-DD'));
      daysAddedAfter++;
    }
    i++;
  }

  return dates;
};


const getFilterForCarts = (userId, direction) => {
  let range = direction === 'future' ? getDaysRange(new Date(), 0, 15) : getDaysRange(new Date(), 20, 0, false);
  let queryFilter = {
    userId: userId,
    daysSelected: { 'in-array': range }
  };
  return {
    queryFilter,
    range
  };
}

// Realiza la consulta a CartsModel para obtener los pedidos del usuario
export const getOrdersOfUser = async (userId, direction) => {
  let { queryFilter, range } = getFilterForCarts(userId, direction);
  let orders = await CartsModel.filterByAttributes(queryFilter);
  // ordena por fecha
  orders = _.sortBy(orders, 'createdAt');
  // Filtra los pedidos eliminados
  orders = _.filter(orders, (order) => !order.data?.deleted);
  return {
    orders,
    range
  };
};

// Formatea los datos de los pedidos para obtener daysData
// orders format temp1
// [{
//   "id": "KOTdAZi3d5DcANonRb2R",
//   "institutionId": "cGTGP0DTF8Y46in6TRAH",
//   "itemsInBags": [
//     {
//       "date": "2024-10-30",
//       "bagItems": [
//         {
//           "qty": 2,
//           "id": "I8wPE1nHDwtq3ta0JeeK",
//           "price": "20000"
//         }
//       ],
//       "total": 40000
//     }
//   ],
//   "deleted": true,
//   "deletedDate": "2024-10-29T16:20:21.866Z",
//   "daysSelected": [
//     "2024-10-30"
//   ],
//   "userId": "KKjPnXHKUp2Iiu5s9uRE",
//   "createdAt": "2024-10-29T16:19:55.527Z",
//   "modifiedAt": "2024-10-29T16:20:21.866Z",
//   "total": 40000
// }, ...orders]
// return format
// [{ date, items: [{ name, qty }, ...] }]
export const formatOrderData = (orders) => {
  const daysData = [];
  // Iterar sobre los pedidos
  orders.forEach((order) => {
    // Iterar sobre los itemsInBags del pedido
    order.data.itemsInBags.forEach((itemInBag) => {
      // Buscar el día en daysData
      let dayIndex = daysData.findIndex((day) => day.date === itemInBag.date);
      // Si el día no existe, crearlo
      if (dayIndex === -1) {
        daysData.push({
          date: itemInBag.date,
          items: [],
        });
        dayIndex = daysData.findIndex((day) => day.date === itemInBag.date);
      }

      // Agregar los items del día contemplando que un item puede tener un extra distinto (item.withExtra) entonces dos items con el mismo id pero diferentes extra es un item diferente en la nueva lista
      itemInBag.bagItems.forEach((item) => {
        if (item.deleted) {
          return;
        }
        
        const existingItem = daysData[dayIndex]?.items.find((existingItem) => existingItem.id === item.id && _.isEqual(existingItem.withExtra, item.withExtra));
        if (existingItem) {
          existingItem.qty += item.qty;
          // update cartDocs, push if not exist, search using order.id
          let cartDoc = existingItem.cartDocs.find((cartDoc) => cartDoc.id === order.id);
          if (!cartDoc) {
            existingItem.cartDocs.push(order);
          }
        } else {
          daysData[dayIndex]?.items.push({
            id: item.id,
            price: item.price,
            qty: item.qty,
            withExtra: item.withExtra,
            cartDocs: [order]
          });
        }
      });
    });
  });

  // Ordenar los datos por fecha
  daysData.sort((a, b) => new Date(a.date) - new Date(b.date));
  
  return daysData;
};

export const getItemsQty = (items = []) => {
  return items.reduce((acc, item) => {
    return acc + item.qty;
  }, 0);
};

const getTotalPrice = (items) => {
  return items.reduce((acc, item) => {
    return acc + item.price * item.qty;
  }, 0);
};

const OpenCartBtn = ({ item, onShowCartModal }) => {
  const [popoverEvent, setPopoverEvent] = useState(null);
  const elementId = useRef(nanoid());

  const handleOpenPopover = (event) => {
    setPopoverEvent(event);
  };

  const handleClosePopover = () => {
    setPopoverEvent(null);
  };

  if (item.cartDocs.length === 1) {
    return (
      <IonButton color="medium" shape="round" size="small" fill="clear" onClick={() => onShowCartModal(item.cartDocs[0])}>
        <IonIcon slot="start" icon={receiptOutline} />
        Ver pedido
      </IonButton>
    );
  } else if (item.cartDocs.length > 1) {
    return (
      <div>
        <IonButton 
          color="medium" 
          shape="round" 
          size="small" 
          fill="clear" 
          id={elementId.current}
          onClick={handleOpenPopover}
        >
          <IonIcon slot="start" icon={receiptOutline} />
          Ver pedidos
        </IonButton>

        <IonPopover 
          isOpen={!!popoverEvent} 
          event={popoverEvent} 
          onDidDismiss={handleClosePopover}
          trigger={elementId.current}
        >
          <IonList>
            {item.cartDocs.map((cartDoc, index) => (
              <IonItem button key={index} onClick={() => { 
                  onShowCartModal(cartDoc);
                  handleClosePopover();
              }}>
                <IonLabel>{dayjs(cartDoc.createdAt).format('DD/MM/YYYY')}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>
      </div>
    );
  }

  return null;
};

const OrdersList = (props) => {
  const { daysData, itemsById, itemClassName, onShowCartModal, emptyMessage } = props;

  let daysDataClened = [];

  daysData.forEach((day) => {
    const itemsQty = getItemsQty(day.items);
    if (itemsQty) {
      daysDataClened.push(day);
    }
  });

  return (
    daysDataClened.length > 0 ? (
      daysDataClened.map((day) => {
        const itemsQty = getItemsQty(day.items);
        const itemsPrice = getTotalPrice(day.items);

        if (!itemsQty) {
          return null;
        }

        return (
          <div key={day.date} className={`p-2 space-y-2 rounded-md ${itemClassName}`}>
            <div className="py-1 flex flex-row place-content-between">
              <div className="basis-1/2">
                <h2 className="text-black text-lg font-regular uppercase leading-none tracking-wide">
                  {day?.date && dayjs(day.date).format('dddd D')}
                  {/* show "hoy" badge */}
                  {day?.date === dayjs().format('YYYY-MM-DD') ? (
                    <span className="relative -top-0.5 ml-1.5 bg-brand-red rounded-full px-2 py-0.5 text-xs text-white">Hoy</span>
                  ) : null}
                </h2>
                <h2 className="text-gray-600 text-xs font-regular capitalize leading-none tracking-wide">
                  {day?.date && dayjs(day.date).format('MMMM, YYYY')}
                </h2>
              </div>
              
              {itemsQty ? (
                <div className="basis-1/2 text-right">
                  <div className="mr-2 inline-block text-center p-0.5 w-6 h-6 text-sm font-semibold bg-gray-100 text-gray-700 rounded-full">
                    {itemsQty}
                  </div>
                  Total:
                  <h3 className="inline pl-1 text-gray-600 mt-1 text-md font-normal leading-none tracking-wide">
                    {priceFormat(itemsPrice || 0)}
                  </h3>
                </div>
              ) : (
                <div className="basis-1/2 text-right">
                  <IonButton color="primary" shape="round" size="small" fill="outline" onClick={() => {}}>
                    <IonIcon slot="start" icon={restaurant} />
                    Pedir
                  </IonButton>
                </div>
              )}
            </div>

            {day.items?.length > 0 ? (
              <div className={`cursor-default`}>
                {day.items.map((item, index) => (
                  <div key={index} className={`w-full flex flex-row text-left grow items-center ${index === day.items.length - 1 ? 'border-none' : 'border-b border-gray-200 pb-2 mb-2'}`}>
                    {/* Ajusta el contenido de cada item según tus necesidades */}
                    <div className="">
                      {/* Ejemplo: Mostrar una imagen */}
                      <img
                        src={getImageURL(itemsById[item.id]?.data.images[0], 'xs')}
                        alt={itemsById[item.id]?.data.name}
                        className="h-[100px] min-w-[120px] w-[120px] object-cover rounded-md shadow-sm border border-gray-200"
                      />
                    </div>
                    <div className="px-2 space-y-1">
                      <div className="font-semibold text-sm">
                        {itemsById[item.id]?.data.name}
                        {item.withExtra ? ` con ${itemsById[item.withExtra]?.data.name}` : ''}
                      </div>
                      <div className="space-x-2 text-xs">
                        <span className="text-gray-700">Cantidad: {item.qty}</span>
                        <span className="text-gray-700">Precio: {priceFormat(item.price * item.qty)}</span>
                      </div>
                      <div className="">
                        <OpenCartBtn item={item} onShowCartModal={onShowCartModal} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        );
      })
    ) : (
      <div className="text-gray-600 text-center">
        {emptyMessage}
      </div>
    )
  );
}


// data = [ { date, items: [{ id, qty, withExtra }, ...] }]
const fechItemsDocs = async (data) => {
  let itemsIds = [];
  data.forEach((day) => {
    day.items.forEach((item) => {
      itemsIds.push(item.id);
      if (item.withExtra) {
        itemsIds.push(item.withExtra);
      }
    });
  });
  let itemsDocs = [];
  const itemsIdsChunks = chunkArray(itemsIds, 30);
  for (const idsChunk of itemsIdsChunks) {
    let docs = await ItemModel.filterByAttributes({ id: { 'in': idsChunk } });
    itemsDocs = itemsDocs.concat(docs);
  }
  let itemsById = {};
  itemsDocs.forEach((item) => {
    itemsById[item.id] = item;
  });
  return itemsById;
};

const Orders = ({ userId, orderType, onShowCartModal, toggleOrdersView }) => {
  const [ daysData, setDaysData ] = useState([]);
  const [ itemsById, setItemsById ] = useState([]);

  const fetchOrdersData = async (orders) => {
    // ordena por fecha
    orders = _.sortBy(orders, 'createdAt');
    // Filtra los pedidos eliminados
    orders = _.filter(orders, (order) => !order.data?.deleted);
    const data = formatOrderData(orders);
    if (orderType === 'past') {
      data.reverse();
    }
    const itemsById = await fechItemsDocs(data);
    setItemsById(itemsById);
    setDaysData(data);
  };

  // useEffect(() => {
  //   if (userId) {
  //     fetchOrdersData();
  //   }
  // }, [userId, orderType]);

  useEffect(() => {
    let unsubscribe = null;
    let { queryFilter } = getFilterForCarts(userId, orderType);
    (async () => {
      let result = await CartsModel.filterByAttributes(
        queryFilter,
        {
          onSnapshot: (orders) => {
            fetchOrdersData(orders);
          }
        }
      );
      unsubscribe = result.unsubscribe;
    })();
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [userId, orderType]);

  return (<>
    <h2 className="py-4">
      <div className="flex place-content-between items-center">
        <div className="text-sm font-semibold text-gray-800 uppercase tracking-wide">
          {orderType === 'future' ? 'Órdenes Futuras' : 'Órdenes Anteriores'}
        </div>
        <IonButton 
          color={orderType === 'future' ? 'light' : 'primary'} 
          size="small" 
          onClick={toggleOrdersView}
        >
          {orderType === 'future' ? 'Ver Anteriores' : 'Ver Futuras'}
        </IonButton>
      </div>
    </h2>
    <div className="space-y-4">
      <OrdersList
        daysData={daysData}
        itemsById={itemsById}
        itemClassName={orderType === 'future' ? 'border border-brand-red' : 'border border-brand-yellow'}
        onShowCartModal={onShowCartModal}
        emptyMessage={orderType === 'future' ? 'Tus órdenes futuras se mostrarán aquí' : 'No hay órdenes anteriores'}
      />
    </div>
  </>);
};

const NextShippingCalendarList = ({ userId, onShowCartModal }) => {
  const [isViewingFutureOrders, setIsViewingFutureOrders] = useState(true);

  const toggleOrdersView = () => {
    setIsViewingFutureOrders(!isViewingFutureOrders);
  };

  return (
    <div className="next-shipping-calendar-list">
      {isViewingFutureOrders ? (
        <Orders userId={userId} orderType="future" onShowCartModal={onShowCartModal} toggleOrdersView={toggleOrdersView} />
      ) : (
        <Orders userId={userId} orderType="past" onShowCartModal={onShowCartModal} toggleOrdersView={toggleOrdersView} />
      )}
    </div>
  );
};

export default NextShippingCalendarList;