import { useEffect, useState } from 'react';


export const DocsFilter = ({ Model, isAllowed, docs, docsListed, setDocs, setDocsListed }) => {
  const [search, setSearch] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const filterList = () => {
    if (search?.length >= 3) {
      const filtered = docs.filter(doc => {
        return doc.data.name?.toLowerCase().includes(search.toLowerCase());
      });
      setDocsListed(filtered);
    } else {
      setDocsListed(docs.slice(0, 30));
    }
  };

  useEffect(() => {
    if (docs.length) {
      filterList();
    }
  }, [search, docs]);

  const handleSearchChange = (e) => {
    const value = e.target.value;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      setSearch(value);
    }, 500);

    setDebounceTimeout(newTimeout);
  };

  return (
    <div className="rounded-md border border-gray-300 overflow-hidden">
      <input
        type="text"
        onChange={handleSearchChange}
        className="appearance-none text-center border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
        placeholder="Buscar por nombre"
      />
    </div>
  );
};