import panel from './panel';
import entity from './entity';
import user from './user';
import cart from './cart';
import gastronomicDailyCart from './gastronomicDailyCart';
import institution from './institution';
import customerBilling from './customerBilling';

const modules = [
  panel,
  entity,
  user,
  cart,
  gastronomicDailyCart,
  institution,
  customerBilling
];
/**
 * Module Structure {
 *    // Set Permissions for data and sections
 *    // Custom component parts
 * 
 *    slug,
 *    label,
 * 
 *    entities: {
 *      [slug]: {
 *        entity: { id, nameSlug, ... },
 *        filterMenuList: [ { id, entityId, nameSlug, filters: [ taxonomyTypeId ] ... },  ...],
 *        taxonomyTypesList: [ { id, entityId, type, nameSlug, ... },  ...],
 *      }
 *    },
 * 
 *    permissions: {
 *      [resourceSlug]: [...actionListSlugs]
 *    },
 * 
 *    routesPublic,
 *    routesAdmin: {
 *      [slug]: {
 *        [action]: {
 *          permission: { resource, action },
 *          Content // param { parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }
 *        }
 *      }
 *    },
 * 
 *    components: {
 *      main: {
 *        CustomRoutes, // param { Route, ProtectedRoute }
 *        AdminHomeBeforeLinksList, // param { isAllowed, user }
 *        AdminHomeAfterLinksList, // param { isAllowed, user }
 *        AdminConfigBeforeLinksList, // param { isAllowed }
 *        AdminConfigAfterLinksList, // param { isAllowed }
 *      },
 * 
 *      [slug]: {
 *    
 *        // Custom DataType for Entity, require 'coded' as the taxonomyType
 *        codedDataTypes: {
 *          [entityTaxonomyType.nameSlug]: {
 *            RenderForm, // param { value, onChange, ctx: { title, taxonomyTypeData, param, props } }
 *            RenderShow // param { displayedValue, doc, field }
 *          }
 *        },
 * 
 *        crud: {
 *          settings: () => ({
 *             title,
 *             reorder,
 *             showBtnAdd,
 *             showBtnShow,
 *             showBtnDelete,
 *             showBtnUpdate,
 *             showBtnMove,
 *             addButtonPermissionAction,
 *             addButtonLabel
 *          }), 
 *          ListFilterCustom, // param { Model, isAllowed }
 *          ListHeaderExtraActions, // param { Model, isAllowed, docs, docsListed, setDocs, setDocsListed }
 *          UnderForm, // param { values }
 *          UnderListItemTitle, // param { doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }
 *          UnderListItemHeader, // param { doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }
 *        }
 *        
 *      }
 *    },
 * 
 *   events: {
 *     [slug]: {
 *       beforeSave, 
 *         // param { entitySlug, taxonomyTypesDocList }, formValues
 *         // return formValues
 *       afterSave, 
 *         // param { entitySlug, taxonomyTypesDocList }, itemDoc
 *         // return undefined
 *     }
 *   }
 * 
 * }
 */

export const boot = () => {
  let actionsByResource = {};

  const modulesBooted = modules.map((moduleBoot) => {
    const definitions = moduleBoot({ setPermissions });
    // map actions by resource for permissions
    definitions?.permissions?.forEach(permissions => {
      actionsByResource[permissions?.resource] = permissions;
    });
    return definitions;
  });
  return {
    modules: modulesBooted,
    actionsByResource
  }
};

export const setPermissions = ({ slug, label, actionsToAdd=[], type, actions }) => {
  return {
    resource: slug,
    label,
    type,
    actions: actions || [
      'read',
      'list',
      'create',
      'update',
      'delete',
      ...actionsToAdd
    ]
  }
}