import { useEffect,useState } from "react";
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { getImageURL, numberFormat, priceFormat } from "../../libs/utils";
import config from "../../config";
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import Model from "../../libs/ModelClass";
import { useEntityFullBySlug } from "../entity/Entity";
import EntityFilterMenuDisplay from "../../components/EntityFilterMenuDisplay";
import dataToQueryFormatter from "../../components/Filters/dataToQueryFormatter";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { attachDocsAndGetTotal, extractProductsAndQtys, extractProductsCanceledAndQtys } from "../customerBilling/BillingProductsCard";
import printJS from "print-js";
import BtnLoading from "../../components/ui/BtnLoading";
import { IonItem } from "@ionic/react";
import ActionsDropdown from "../../components/ui/ActionsDropdown";
import {
  filterCircleOutline
} from 'ionicons/icons';
import { IoPrintOutline } from "react-icons/io5";
import { PrintTagsBtn } from "./PrintTagsBtn";


const filterMenuSlug = "crud";
const maxResultsForCustomer = 4;

const MaterialsModel = Model.extend('-materials');
const FormulasModel = Model.extend('-formulas');
const CartsModel = Model.extend(config?.modules?.cart?.cartsEntitySlug);
const ProductsModel = Model.extend(config?.modules?.cart?.cartItemEntitySlug);
const UsersModel = Model.extend('usersProfiles');
const InstitutionsModel = Model.extend('institutions');

const sortFields = [{
  name: 'nombre',
  field: 'userDoc.data.firstName',
  direction: 'asc'
}, {
  name: 'fecha del registro',
  field: 'createdAt',
  direction: 'desc'
}]


const getMap = (user) => {
  if (user?.rolesDoc?.data?.nameSlug === 'superAdmin') {
    return {
      listShowUser: true,
      listShowInstitution: true,
      filtersPatch: (filters) => (filters)
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'mainCustomer') {
    return {
      listShowUser: true,
      listShowInstitution: false,
      filtersPatch: (filters) => ({ 
        ...filters,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  }
  else if (user?.rolesDoc?.data?.nameSlug === 'customer') {
    return {
      listShowUser: false,
      listShowInstitution: false,
      limitResults: maxResultsForCustomer,
      filtersPatch: (filters) => ({ 
        ...filters,
        userId: user?.userDoc?.id,
        institutionId: user?.userDoc?.data?.institutionId
      })
    };
  } 
  return {
    listShowUser: false,
    listShowInstitution: false,
    limitResults: false,
    filtersPatch: (filters) => (filters)
  };
};

const usePermissionsMap = (user) => {
  return getMap(user);
};

const ListItemsFiltered = ({ filtersSelected, setFiltersSelected, user, isAllowed, onShow }) => {
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug: config?.modules?.cart?.cartsEntitySlug, filterMenuSlug });
  const permissionsMap = usePermissionsMap(user);
  const [ institutionsDocs, setInstitutionsDocs ] = useState([]);
  const [ institutionsById, setInstitutionsById ] = useState([]);
  const [ materialsDocs, setMaterialsDocs ] = useState([]);
  const [ formulasDocs, setFormulasDocs ] = useState([]);
  const [ productsDocs, setProductsDocs ] = useState([]);
  const [ materialsSpecs, setMaterialsSpecsByDay ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ sortFieldItemsByUser, setSortFieldItemsByUser ] = useState(sortFields[0]);
  
  useEffect(() => {
    filterMenuTaxonomyTypes && fetchFilteredDocs();
  }, [filterMenuTaxonomyTypes, filtersSelected]);

  useEffect(() => {
    if (materialsSpecs?.productsByUser && sortFieldItemsByUser?.field) {
      let productsByUser = _.sortBy(materialsSpecs.productsByUser, sortFieldItemsByUser?.field);
      if (sortFieldItemsByUser?.direction === 'desc') {
        productsByUser = productsByUser.reverse();
      }
      setMaterialsSpecsByDay({
        ...materialsSpecs,
        productsByUser
      });
    }
  }, [sortFieldItemsByUser]);

  const fetchFilteredDocs = async () => {
    if (!filtersSelected?.createdAt) { return; }
    setLoading(true);
    // replace createdAt as date filter to daysSelected instead
    const daySelected = dayjs(filtersSelected.createdAt).utc().format('YYYY-MM-DD');
    const filterQuery = dataToQueryFormatter(permissionsMap.filtersPatch(filtersSelected), filterMenuTaxonomyTypes);
    filterQuery.daysSelected = { 'in-array': [ daySelected ] };
    Reflect.deleteProperty(filterQuery, 'createdAt');
    let filteredCarts = await CartsModel.filterByAttributes(filterQuery);
    filteredCarts = filteredCarts.filter(doc => !doc.data?.deleted);
    filteredCarts = filteredCarts.sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));
    // fetch related documents
    const institutions = institutionsDocs.length ? institutionsDocs : await fetchAllOnstitutions();
    const materials = materialsDocs.length ? materialsDocs : await fetchAllMaterials();
    const formulas = formulasDocs.length ? formulasDocs : await fetchAllFormulas();
    const products = productsDocs.length ? productsDocs : await fetchAllProducts();
    // process data by day
    const productsWithQtyAndDocs = extractAndFetchProductsAndQtysFromCarts(daySelected, filteredCarts, products, formulas);
    const itemsByInstitution = groupByInstitution(daySelected, filteredCarts, products, formulas, institutions);
    const productsByUser = await productsByUserOfInstitution(daySelected, filteredCarts, products);
    /////// productos individuales cancelados, no contempla los cart eleminiados por completo
    const productsCanceledByUser = await productsCanceledByUserOfInstitution(daySelected, filteredCarts, products);
    const totalsByMaterial = await calcMaterialsByDay(productsWithQtyAndDocs, materials);
    setMaterialsSpecsByDay({
      totalsByMaterial,
      productsWithQtyAndDocs,
      filteredCarts,
      productsByUser,
      productsCanceledByUser,
      itemsByInstitution
    });
    setLoading(false);
  };

  const groupByInstitution = (daySelected, carts, products, formulas, institutions) => {
    const cartsByInstitutionId = _.groupBy(carts, 'data.institutionId');
    return _.map(cartsByInstitutionId, (cartsOfInstitution, institutionId) => {
      const institutionDoc = _.find(institutions, (doc) => ( doc.data.id === institutionId ));
      const itemsQty = extractFromCarts(products, formulas, addItemToListCb({ daySelected, list: cartsOfInstitution }));
      return {
        itemsQty,
        institutionDoc
      };
    });
  };

  const extractAndFetchProductsAndQtysFromCarts = (daySelected, carts, products, formulas) => {
    return extractFromCarts(products, formulas, addItemToListCb({ daySelected, list: carts }));
  };

  const addItemToListCb = ({ daySelected, list }) => (addItemToList) => {
    list.forEach((cartDoc) => {
      const bagOfDay = cartDoc.data.itemsInBags.find(bag => bag.date === daySelected);
      bagOfDay.bagItems.forEach((itemInBag) => {
        if (!itemInBag.deleted) {
          addItemToList(itemInBag.id, itemInBag.qty);
          if (itemInBag.withExtra) {
            addItemToList(itemInBag.withExtra, itemInBag.qty);
          }
        }
      });
    });
  };

  const calcMaterialsByDay = async (productsWithQtyAndDocs, materialsDocs) => {
    // get totals by material
    let totalsByMaterial = [];
    const addMaterialToList = (productQty) => ({ material, measure, qty }) => {
      const itemInList = totalsByMaterial.find(itemWithQty => itemWithQty.materialId === material);
      if (itemInList) {
        // aumentar por measure
        const measureInList = itemInList.qtysAndMeasures.find(qtyByMeasure => qtyByMeasure.measure === measure);
        if (measureInList) {
          measureInList.qty += qty * productQty;
        } else {
          itemInList.qtysAndMeasures.push({
            measure,
            qty
          });
        }
      } else {
        totalsByMaterial.push({ 
          materialId: material, 
          qtysAndMeasures: [{
            measure,
            qty: qty * productQty
          }],
          materialDoc: materialsDocs.find(materialDoc => materialDoc.id === material)
        });
      }
    };
    productsWithQtyAndDocs.forEach(({ formulaDoc, qty }) => {
      formulaDoc?.data?.materials?.forEach(addMaterialToList(qty));
    });
    totalsByMaterial = _.sortBy(totalsByMaterial, ['materialDoc.data.name']);
    return totalsByMaterial;
  };

  const extractFromCarts = (products, formulas, cartsExtractor) => {
    const productsIdsWithQty = [];
    const addItemToList = (itemId, qty) => {
      const itemInList = productsIdsWithQty.find(itemWithQty => itemWithQty.id === itemId);
      if (itemInList) {
        itemInList.qty += qty;
      } else {
        productsIdsWithQty.push({ id: itemId, qty });
      }
    };
    cartsExtractor(addItemToList);
    let productsWithQtyAndDocs = productsIdsWithQty.map(({ id, qty }) => {
      const map = {
        productId: id,
        qty,
        productDoc: products.find(product => product.id === id),
      };
      if (map.productDoc?.data?.materials) {
        map.formulaDoc = formulas.find(formula => formula.id === map.productDoc.data.materials);
      } else {
        console.log('falta productDoc.data.materials para', map.productDoc);
      }
      return map;
    });
    productsWithQtyAndDocs = _.sortBy(productsWithQtyAndDocs, ['productDoc.data.name']);
    return productsWithQtyAndDocs;
  };
  
  const fetchAllOnstitutions = async () => {
    let docs = await InstitutionsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    let docsById = _.groupBy(docs, 'id');
    setInstitutionsById(docsById);
    setInstitutionsDocs(docs);
    return docs;
  };

  const fetchAllMaterials = async () => {
    let docs = await MaterialsModel.getAll();
    docs = docs.filter(doc => doc.data.deleted !== true);
    setMaterialsDocs(docs);
    return docs;
  };

  const fetchAllFormulas = async () => {
    let docs = await FormulasModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    setFormulasDocs(docs);
    return docs;
  };

  const fetchAllProducts = async () => {
    let docs = await ProductsModel.getAll();
    docs = docs.filter(docs => docs.data.deleted !== true);
    setProductsDocs(docs);
    return docs;
  };

  const productsByUserOfInstitution = async (daySelected, cartsDocs, products) => {
    if (!filtersSelected?.institutionId) {
      return null;
    }
    let users = await UsersModel.filterByAttributes({ institutionId: filtersSelected?.institutionId });
    users = users.filter(docs => docs.data.deleted !== true);
    let itemsInBags = extractProductsAndQtys([daySelected], cartsDocs);
    attachDocsAndGetTotal(itemsInBags, products, users);
    itemsInBags = itemsInBags.filter(docs => docs.userDoc);
    itemsInBags = _.sortBy(itemsInBags, (itemInBag) => itemInBag.userDoc.data.firstName);
    return itemsInBags;
  };
  
  const productsCanceledByUserOfInstitution = async (daySelected, cartsDocs, products) => {
    if (!filtersSelected?.institutionId) {
      return null;
    }
    let users = await UsersModel.filterByAttributes({ institutionId: filtersSelected?.institutionId });
    users = users.filter(docs => docs.data.deleted !== true);
    let itemsInBags = extractProductsCanceledAndQtys([daySelected], cartsDocs);
    attachDocsAndGetTotal(itemsInBags, products, users, true);
    itemsInBags = itemsInBags.filter(docs => docs.userDoc);
    itemsInBags = _.sortBy(itemsInBags, (itemInBag) => itemInBag.userDoc.data.firstName);
    return itemsInBags;
  };

  const doPrintList = () => {
    printJS({
      printable: 'products-to-print',
      type: 'html',
      targetStyles: '*',
      maxWidth: 1000,
      title: 'Productos por consumidor',
      style: `
        #products-to-print {
          font-size: 10px;
        }
        .print-only {
          visibility: visible;
          display: block;
        }
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0 10px;
        }
        th, td {
          padding: 4px;
        }
        th {
          text-align: left;
          border-bottom: 1px solid #000;
        }
        td {
          vertical-align: top;
        }
      `
    });
  };  

  const doExcel = async () => {
    let result = _.map(materialsSpecs.productsByUser, ({ userDoc, itemExtraDoc, itemDoc, price, qty, createdAt }, index) => ({
      'Fecha de registro': dayjs(createdAt).tz(config.timezone).format('DD-MM-YYYY HH:mm'),
      'Usuario': (userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || ''),
      'Cédula': numberFormat(userDoc.data.ci),
      'Precio unitario': Number(price), // Asegurar que sea un número
      'Cantidad': Number(qty), // Asegurar que sea un número
      'Precio total': Number(price) * Number(qty), // Multiplicación numérica
      'Producto': itemExtraDoc ? (itemDoc?.data?.name + ' con ' + itemExtraDoc?.data?.name) : (itemDoc?.data?.name)
    }));  
    // Generar el workbook y worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(result, {
      header: _.keys(result[0])
    });
  
    let institutionDoc = await InstitutionsModel.findById(filtersSelected?.institutionId);
    let fileName = `consumisión ${institutionDoc.data.name} día ${dayjs(filtersSelected.createdAt).tz(config.timezone).format('DD-MM-YYYY')}.xlsx`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
    XLSX.writeFile(workbook, fileName, { compression: true });
  };  

  const TotalsByItem = ({ onSelect, institutionDoc, itemsList, title, imgSrc, classes }) => {
    return (<>
      {itemsList?.length ? (<>
        <div className="">
          {imgSrc ? (
            <div className="flex flex-row items-center gap-4 mb-4">
              <img src={imgSrc} alt={title} className="w-16 h-16 rounded-sm shadow-md" />
              <a onClick={() => onSelect(institutionDoc)}>
                <h3 className="text-center font-semibold underline cursor-pointer">{title}</h3>
              </a>
            </div>
          ) : (
            <a onClick={() => onSelect(institutionDoc)}>
              <h3 className="mb-4 text-center font-semibold underline cursor-pointer">{title}</h3>
            </a>
          )}
          <div className="px-2 border border-gray-200 rounded-md">
            {itemsList?.map(({ productDoc, qty }, index) => (
              <div className={`
                flex flex-row place-content-between
                py-2 border-b border-gray-200 last:border-none 
              `} key={index}>
                <span className="text-sm font-regular">{productDoc?.data?.name}</span>
                <span className="text-sm font-regular">{numberFormat(qty, 3)}</span>
              </div>
            ))}
          </div>
        </div>
      </>) : (
        <div className="text-center">
          No hay pedidos para el día
        </div>
      )}
    </>);
  };

  const TotalsOfMaterials = ({ itemsList, title, classes }) => {
    return (<>
      {itemsList?.length ? (<>
        <div className="mt-12">
          <h3 className="text-center font-semibold mb-4">{title}</h3>
          <div className="px-2 border border-gray-200 rounded-md">
            {itemsList?.map(({ materialId, materialDoc, qtysAndMeasures }, index) => (
              <div className={`
                flex flex-row place-content-between
                py-2 border-b border-gray-200 last:border-none 
              `} key={materialId}>
                <div className="">
                  <span className="text-sm font-regular">{materialDoc.data.name}</span>
                </div>
                <div className={`text-right flex flex-col place-content-between`}>
                  {qtysAndMeasures?.map(({ qty, measure }, index) => (
                    <span key={index} className="text-sm font-regular">{numberFormat(qty, 3)} {measure}</span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>) : null}
    </>);
  };

  const ItemsByUser = ({ itemsList, title, classes }) => {
    return (<>
      {filtersSelected?.institutionId ? (<>
        <h3 className={`text-center font-semibold mb-2 ${classes?.title}`}>{title}</h3>
        <div className="mb-4 flex flex-row place-content-center gap-2">
          <div className="hidden md:block">
            <BtnLoading
              label="Lista"
              onClick={doPrintList}
              icon={<IoPrintOutline className="text-xl" />}
              withLoading
              colorClass="text-white"
              className="px-3 py-1 text-sm !text-black bg-gray-200 shadow-none hover:bg-gray-300"
            />
          </div>
          <div className="hidden md:block">
            <PrintTagsBtn {...{itemsList, institutionsById, date: filtersSelected?.createdAt}} />
          </div>
          <div className="hidden md:block">
            <BtnLoading
              label="Excel"
              onClick={doExcel}
              withLoading
              colorClass="text-white"
              className="px-3 py-1 text-sm !text-black bg-gray-200 shadow-none hover:bg-gray-300"
            />
          </div>
          <ActionsDropdown classes={{ actionsContainer: '!block', actionsPopover: 'rounded-md overflow-hidden shadow-xl', ionIcon: '!text-xl' }} ionIcon={filterCircleOutline}>
            {sortFields.map((fieldSpect) => (
              <IonItem button key={fieldSpect.field} size="small" color={sortFieldItemsByUser === fieldSpect ? 'secondary' : 'medium'} onClick={() => setSortFieldItemsByUser(fieldSpect)}>
                {fieldSpect.name}
              </IonItem>
            ))}
          </ActionsDropdown>
        </div>
        <div className="px-2 py-2 border border-gray-200 rounded-md">
          <table className="text-xs w-full border-separate" style={{ borderSpacing: '0 10px' }} id="products-to-print">
            <tbody className="gap-2">
              {itemsList?.map(({ price, qty, userDoc, itemDoc, itemExtraDoc, day, createdAt }, index) => (
                <tr key={index}>
                  <td className="text-right pr-2 align-top">{index + 1}</td>
                  <td className="space-x-2">
                    <span className="font-semibold">
                      {(userDoc.data.firstName || '') + ' ' + (userDoc.data.lastName || '')}
                    </span>
                    <span>
                      CI: {numberFormat(userDoc.data.ci)}
                    </span>
                    <span>
                      {priceFormat(price * qty)}
                    </span>
                    <span>
                      {dayjs(createdAt).tz(config.timezone).format('DD-MM-YYYY HH:mm')}
                    </span>
                    <br />
                    <span>
                      {qty}
                    </span>
                    <span>
                      {itemDoc?.data?.name}
                    </span>
                    {itemExtraDoc ? (
                      <span>
                        con {itemExtraDoc?.data?.name} como guarnición
                      </span>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>) : null}
    </>);
  };

  const goToInstitution = (institutionDoc) => {
    setFiltersSelected({
      ...filtersSelected,
      institutionId: institutionDoc?.id
    });
  }
  
  return (<>
    {!filtersSelected?.createdAt ? (
      <div className="text-center">
        Seleccione una fecha
      </div>
    ) : (<>
      {loading ? (
        <div className="mx-auto w-24">
          <BadgeLoading size="md" />
        </div>
      ) : (<>
        <div className="space-y-10">
          <h3 className="text-2xl text-center font-semibold mb-4">
            {dayjs(filtersSelected.createdAt).tz(config.timezone).format("dddd, DD / MMMM / YYYY")}
          </h3>

          <TotalsByItem itemsList={materialsSpecs?.productsWithQtyAndDocs} title="" />
          <TotalsOfMaterials itemsList={materialsSpecs?.totalsByMaterial} title="Materias primas del día" />

          <div className="space-y-4">
            <h3 className="text-2xl text-center font-semibold mb-4">Platos por Institución</h3>
            {materialsSpecs?.itemsByInstitution?.map(({ itemsQty, institutionDoc }, index) => (
              <div key={index}>
                <TotalsByItem itemsList={itemsQty} onSelect={goToInstitution} institutionDoc={institutionDoc} title={institutionDoc?.data.name} imgSrc={getImageURL(institutionDoc?.data.images[0], 'xs')} />
              </div>
            ))}
          </div>

          <ItemsByUser itemsList={materialsSpecs?.productsByUser} title="Platos pedidos por persona" date={filtersSelected.createdAt} />
          <ItemsByUser itemsList={materialsSpecs?.productsCanceledByUser} title="Platos cancelados por persona" classes={{title: 'text-red-700'}} />
        </div>
      </>)}
    </>)}
  </>);
};

export const RouteCartProduction = ({ parsedParams, user, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {  
  const [filtersSelected, setFiltersSelected] = useState({});
  const permissionsMap = usePermissionsMap(user);
  
  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title='Platos y Materias primas'
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Producción'
      }]}
    >
      <div className="ion-padding pb-32 flex flex-col lg:flex-row">
        <div className="lg:w-2/5">
          <EntityFilterMenuDisplay
            entitySlug={config.modules.cart.cartsEntitySlug}
            filterMenuSlug={filterMenuSlug}
            filterData={filtersSelected}
            onFormChange={(formValues) => {
              setFiltersSelected(formValues);
            }}
            onFormClear={() => {
              setFiltersSelected(null);
            }}
            style={'search-collapsible-panel'}
            paramsByFieldSlug={{
              'userId': {
                isShow: permissionsMap?.listShowUser,
                optionsMapper: (docs) => docs.map((option) => {
                  let label = option?.data?.name;
                  if (option?.data?.ci) {
                    label = label + `, CI: ${option?.data?.ci}`;
                  }
                  if (option?.data?.phone) {
                    label = label + `, ${option?.data?.phone}`;
                  }
                  if (option?.data?.email) {
                    label = label + `, ${option?.data?.email}`;
                  }
                  return {
                    value: option?.id,
                    label
                  };
                })
              },
              'institutionId': {
                isShow: permissionsMap?.listShowInstitution,
                optionsMapper: (docs) => docs.map((option) => ({
                  value: option?.id,
                  label: `${option?.data?.name} | ${option?.data?.city || ''}, ${option?.data?.location || ''}`
                }))
              }
            }}
          />
        </div>

        <div className="mt-4 pt-8 lg:pt-0 lg:mt-0 lg:ml-4 lg:w-3/5">
          <ListItemsFiltered {...{filtersSelected, setFiltersSelected, user, isAllowed}} />
        </div>  
      </div>
    </LayoutAdmin>
  );
};