import { useEffect, useState } from 'react'
import ZoomSlider from "./react-instagram-zoom-slider";
import _ from 'lodash';

/**
 * slidersData = { key, title, imageUrl, thumbUrl }
 */
export default function SliderGallery ({
  slidersData,
  gridClass,
  thumbBtnClass,
  showSliderAtInit=true,
  showGrid=true,
  onClick,
  inHeader,
  inSidebar,
  inFooter,
  getGridFooter,
  gridPlace='bottom',
  children
}) {
  const [ showSlider, setShowSlider ] = useState(showSliderAtInit);
  const [ sliderDimensions, setSliderDimensions ] = useState({width: -1, height: -1});
  
  const slidesElements = slidersData.map(slide =>  (
    <div className="h-full w-full bg-gray-200" key={slide.imageUrl}>
      <img src={slide.imageUrl} className="object-cover w-full rounded-t-lg"
        style={{ height: sliderDimensions.width * 0.8 }}
        alt={slide.title} draggable="false"
      />
    </div>
  ))

  const getThumbClass = (slideKey) => {
    return _.isFunction(thumbBtnClass) ? thumbBtnClass(slideKey) : thumbBtnClass;
  }
  
  return (<>
    {/* Variants Slider */}
    <ZoomSlider slides={slidesElements} slidesData={slidersData} slideIndicatorTimeout={null}
    activeDotColor="#d4ae16" dotColor="#ccc" showButtons={true}
    onResize={contentRect => { setSliderDimensions(contentRect.bounds) }}>
      {({ InnerSlider, currentSlide, setSlide }) => {

        const Grid = (<div className={`${gridClass} xs my-2`}>
          {_.map(slidersData, ({ key, title, thumbUrl }, index) => (
            // thumb
            <button key={key} type="button"
              className={`${getThumbClass(key)} ${currentSlide === index && showSlider ? 'border border-stone-700 scale-110 shadow-xl selected' : 'm-[2px]'}`} 
              onClick={(e) => {
                // toggle
                if (currentSlide === index) {
                  setShowSlider( !showSlider );
                } else {
                  setShowSlider(true);
                }
                setSlide(index);
                onClick && onClick(e, index);
              }}
            >
              <img src={thumbUrl} alt={title} />
              {getGridFooter ? getGridFooter({ key, title, thumbUrl, index }) : ''}
            </button>
          ))}
        </div>)

        const InnerInnerSlider = (<>
          {/* imgs slider, h-0 to hide to avoid flickering */}
          <div className={`bg-white border border-gray-300 shadow-lg rounded-lg z-0 ${showSlider ? '' : 'h-0 opacity-0 overflow-hidden'}`}>
            {InnerSlider}
          </div>
        </>)

        return (
          children ? children({
            currentSlide, setSlide, showSlider, setShowSlider,
            Grid, 
            InnerSlider: InnerInnerSlider
          }) : (<>
            {/* inHeader */}
            {inHeader ? inHeader({ currentSlide, setSlide, showSlider, setShowSlider }) : ''}
            {/* grid top */}
            {showGrid && gridPlace === 'top' ? Grid : ''}
            {/* slider */}
            {InnerInnerSlider}
            {/* grid bottom */}
            {showGrid && gridPlace === 'bottom' ? Grid : ''}
            {/* inSidebar */}
            {inSidebar ? inSidebar({ currentSlide, setSlide, showSlider, setShowSlider }) : ''}
            {/* inFooter */}
            {inFooter ? inFooter({ currentSlide, setSlide, showSlider, setShowSlider }) : ''}
          </>)
        )
      }}
    </ZoomSlider>
  </>);
}