import _ from 'lodash';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import { EntityDocListView } from "../components/EntityDocView";
import { getImageURL } from "../libs/utils";
import PartOfModule, { usePartOfModule } from '../components/Module/PartOfModule';
import { useModule } from "../context/ModuleContext";
import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { useEntityFullBySlug } from '../modules/entity/Entity';


export const useListDoc = (entitySpects, isAllowed) => ({ doc, isSelected, onClick }) => {
  const { entitySlug, taxonomyTypesDocList } = entitySpects;
  return (
    <EntityDocListView
      {...entitySpects}
      doc={doc}
      outstandingOnly={true}
      classes={{
        fieldLabel: "hidden"
      }}
      render={({ ViewData, mainAttr, mainImgAttr }) => (
        <div onClick={onClick} className={`flex flex-row ${isSelected ? 'text-brand-red' : ''} ${onClick ? 'cursor-pointer' : ''} `}>
          {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
            <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
            className="rounded shadow-md hover:shadow-xl h-[70px] w-[70px] object-cover" />
          ) : null}
          <div className="px-2 w-auto">
            <ViewData field={mainAttr} classes={{ fieldContainer: `text-base leading-tight font-semibold ${isSelected ? 'text-brand-red' : ''}` }} />

            {taxonomyTypesDocList.map((taxonomyType) => (
              (![mainAttr, mainImgAttr].includes(taxonomyType.data.nameSlug)) ? (
                <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} classes={{ fieldContainer: `text-xs font-normal text-gray-600` }} />
                // TODO: Agregar decorator y classes al TaxonomyType para View
              ) : null
            ))}

            <PartOfModule
              type="crud"
              action="UnderListItemTitle"
              entitySlug={entitySlug}
              param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
            />
          </div>
          <PartOfModule
            type="crud"
            action="UnderListItemHeader"
            entitySlug={entitySlug}
            param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
          />
        </div>
      )}
    />
  );
};

// TODO
// Mostrar/Ocultar botón ADD
// Mostrar barra de filtros
export function Content({ entityDoc, history }) {  
  const { isAllowed } = useModule();
  const entitySlug = entityDoc?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const location = useLocation();
  const [ refresh, doRefresh ] = useState(0);
  const entitySpects = useEntityFullBySlug({ entitySlug });
  const ListDoc = useListDoc(entitySpects);

  useEffect(() => {
    doRefresh(refresh + 1);
  }, [location]);

  const moduleSettingsFn = usePartOfModule({
    type: 'crud',
    entitySlug,
    action: 'settings',
    param: {}
  });
  const moduleSettings = _.defaults((moduleSettingsFn && moduleSettingsFn()) || {}, {
    title: false,
    reorder: false,
    showBtnAdd: true,
    showBtnShow: true,
    showBtnDelete: true,
    showBtnUpdate: true,
    showBtnMove: true,

    // addButtonPermissionAction,
    // addButtonLabel
  });

  // const ListFilterCustom = usePartOfModule({
  //   type: 'crud',
  //   entitySlug,
  //   action: 'ListFilterCustom',
  //   param: {}
  // });

  const validateForm = () => {};
  const handleBeforeSave = () => {};
  const handleDelete = () => {};
  
  const ExtraActions = ({ docs, docsListed, setDocs, setDocsListed }) => (<>
    <PartOfModule
      type="crud"
      action="ListHeaderExtraActions"
      entitySlug={entitySlug}
      param={{ Model, isAllowed, docs, docsListed, setDocs, setDocsListed }}
    />
  </>);

  const ListBtns = ({ doc }) => {
    if ( isAllowed(entitySlug, ['read']) && moduleSettings.showBtnShow ) {
      return <IonButton
        size="small"
        fill="clear"
        color="primary"
        routerLink={`/admin/entity/${entityDoc?.id}/${doc.id}`}
      >
        Ver
      </IonButton>
    }
    return null;
  };
  

  return (
    <SectionCrudModel
      history={history}
      model={ExtendedModel}
      entitySlug={entitySlug}
      editStyle="route"
      
      navigateTo={(doc) => (`/admin/entity/${entityDoc?.id}/${doc ? doc.id : 'new'}/form`)}
      refresh={refresh}
      
      // callbacks 
      onValidation={validateForm}
      handleBeforeSave={handleBeforeSave}
      onDelete={handleDelete}
      sortDocs={moduleSettings?.sortDocs}

      // UI
      ListItem={ListDoc}
      ListBtns={ListBtns}
      ExtraActions={ExtraActions}

      {...moduleSettings}
    />
  );
}

export function AdminEntityCrud(props) {
  const { entityId } = props.match.params;
  const ExtendedModel = Model.extend('entities');
  const entityDoc = useStateSingleResult({
    Model: ExtendedModel,
    id: entityId
  });
  
  return (
    <LayoutAdmin 
      history={props.history} 
      defaultHref={`/admin`}
      title={entityDoc?.data?.name}
      breadcrumbs={[{
        url: '/admin/entity',
        title: 'Listados'
      }, {
        title: entityDoc?.data?.name
      }]}
    >
      <div className="ion-padding">
        {entityDoc?.data && <Content entityDoc={entityDoc} {...props} />}
      </div>
    </LayoutAdmin>
  );
}
