import { useState } from "react";
import {
  IonAlert,
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonNote,
  IonPopover,
} from "@ionic/react";
import { 
  personCircle,
  createOutline,
  logOut,
} from 'ionicons/icons';
import { useAuth } from "../modules/user/AuthContext";
import { Capacitor } from "@capacitor/core";
import { usePanel } from "../modules/panel/PanelContext";


export const CurrentUserItem = ({ 
  showUserPic = true,
}) => {
  const { user } = useAuth();

  return (
    <IonLabel>
      <div className="flex flex-row gap-2 items-center">
        {showUserPic ? (
          <IonAvatar>
            <img src={user?.fireUser?.photoURL} alt="user profile image" />
          </IonAvatar>
        ) : null}
        <div className="">
          <div className="text-black text-sm">Hola <b>{user?.userDoc?.data?.name || user?.userAuth?.displayName}</b></div>
          {user?.userDoc?.data?.email ? (<div className="text-gray-500 !font-light text-sm">{user?.userDoc?.data?.email}</div>) : null}
          {user?.userDoc?.data?.phone ? (<div className="text-gray-500 !font-light text-sm">{user?.userDoc?.data?.phone}</div>) : null}
          {/* <IonChip>
            <IonAvatar>
              <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
            </IonAvatar>
            <IonLabel>{user?.userAuth?.phone || user?.userAuth?.email}</IonLabel>
          </IonChip> */}
        </div>
      </div>
    </IonLabel>
  );
}

export const UserList = ({
  history,
  showCurrentUser = true,
  showGoToProfile = true,
  showCurrentUserPic = true,
  triggerMode, // "button" | "menu",
  menuTrigger,
  onMenuClose
}) => {  
  const { logout } = useAuth();
  const [ isOpen, setIsOpen ] = useState(false);
  const { setIsUserMenuPopoverOpen } = usePanel();

  const doLogout = async () => {
    await logout();
    if (Capacitor.isNativePlatform()) {
      history.replace('/login');
    } else {
      history.replace('/');
    }
  };
  
  return (<>
    <IonItemDivider>
      <IonLabel>Usuario</IonLabel>
    </IonItemDivider>
    <IonItemGroup>
      {showCurrentUser ? (
        <IonItem>
          <CurrentUserItem showUserPic={showCurrentUserPic} />
        </IonItem>
      ) : null}
      {/* Profile */}
      {showGoToProfile ? (<>
        {triggerMode === 'menu' ? (
          <IonMenuToggle trigger={menuTrigger} autoHide={false}>
            <IonItem button routerLink="/admin/user/data">
              <IonIcon icon={createOutline}></IonIcon>
              <IonLabel className="ml-2">Perfil</IonLabel>
            </IonItem>
          </IonMenuToggle>
        ) : null}
        {triggerMode === 'button' ? (
          <IonItem button routerLink="/admin/user/data" onClick={() => setIsUserMenuPopoverOpen(false)}>
            <IonIcon icon={createOutline}></IonIcon>
            <IonLabel className="ml-2">Perfil</IonLabel>
          </IonItem>
        ) : null}
      </>) : null}
      {/* Logout */}
      <IonItem button onClick={() => setIsOpen(true)}>
        <IonIcon icon={logOut}></IonIcon>
        <IonLabel className="ml-2">Cerrar sesión</IonLabel>
      </IonItem>
      <IonAlert
        header="Desea cerrar la sesión?"
        isOpen={isOpen}
        buttons={[
          {
            text: 'Si, cerrar la sesión',
            role: 'confirm',
            handler: async () => {
              try {
                await doLogout();
              } catch (error) {
                console.error(error.message);
              }
            },
          },
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
            },
          },
        ]}
        onDidDismiss={() => setIsOpen(false)}
      ></IonAlert>
    </IonItemGroup>
  </>);
};

export const UserMenu = (props) => {
  const { children, menuTrigger } = props;
  const { isUserMenuPopoverOpen, setIsUserMenuPopoverOpen } = usePanel();
  const { user } = useAuth();

  const openPopover = (event) => {
    setIsUserMenuPopoverOpen(!isUserMenuPopoverOpen);
  };

  const closePopover = () => {
    setIsUserMenuPopoverOpen(false);
  };

  return (
    <>
      <IonButton
        id={menuTrigger}
        shape="round"
        onClick={(e) => openPopover(e)}
      >
        {user?.fireUser?.photoURL ? (
          <IonAvatar>
            <img src={user?.fireUser?.photoURL} alt="user profile image" />
          </IonAvatar>
        ) : (
          <IonIcon icon={personCircle} size="large"></IonIcon>
        )}
      </IonButton>

      <IonPopover
        trigger={menuTrigger}
        side="bottom" 
        alignment="end"
        isOpen={isUserMenuPopoverOpen}
        onDidDismiss={closePopover}
      >
        {children}
      </IonPopover>
    </>
  );
};