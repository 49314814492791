import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import toast from "react-hot-toast";
import { IonItem } from "@ionic/react";
import _ from 'lodash';
import { LayoutAdmin } from "../../components/LayoutAdmin";
import { useModuleModels } from "../../libs/useModuleModels";
import BtnLoading from "../../components/ui/BtnLoading";
import { useCartData } from "../cart/useCartData";
import IonDrawer from "../../components/ui/IonDrawer";
import ModalAlert from "../../components/ui/ModalAlert";
import ActionsDropdown from "../../components/ui/ActionsDropdown";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { CartItemForPlanning } from "./CartItemForPlanning";
import { InstitutionSelector } from "../institution/InstitutionSelector";
import Model from "../../libs/ModelClass";
import { useAsyncMemo } from "use-async-memo";


const InstitutionModel = Model.extend('institutions');

const panelConfig = ({ windowHeight }) => {
  return {
    breaks: {
      top: { 
        enabled: true, 
        height: (windowHeight || 900) - 40,
        bounce: true
      },
      middle: { enabled: false },
      bottom: { enabled: false },
    },
    dragBy: ['.no-draggable'],
    preventClicks: false,
    initialBreak: 'top',
    backdrop: true,
    backdropOpacity: 0.5,
    buttonDestroy: true,
    bottomClose: true,
    preventClicks: false,
    simulateTouch: false,
    passiveListeners: false
  };
};

export const RoutePlanningItemsOptions = ({ parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, history }) => {
  const [selectedPlan, setSelectedPlan] = useState();
  const [markedPlanId, setMarkedId] = useState();
  const { ModuleModel } = useModuleModels();
  const [refresh, doRefresh] = useState(0);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showOldDocs, setShowOldDocs] = useState(false);
  const [selectedDocForDelete, setSelectedDocForDelete] = useState(false);
  const [showCloneConfirmation, setShowCloneConfirmation] = useState(false);
  const [selectedDocForClone, setSelectedDocForClone] = useState(false);
  const cartData = useCartData();
  const { planning, fetchPlanningItemsOptionsList, selectedInstitution, setSelectedInstitution } = cartData;
  const institutionsDocsById = useAsyncMemo(async () => {
    let docs = await InstitutionModel.getAll();
    return _.mapKeys(docs, (doc) => doc.id);
  }, []);

  useEffect(() => {
    cartData.processPlanningData(false, selectedInstitution?.id, 'planning');
  }, [cartData.selectedInstitution]);

  const add = async () => {
    const PlanningItemsOptionsModel = ModuleModel(config.modules.cart.planningItemsOptionsEntitySlug);
    let docData = {
      startDate: dayjs().utc().add(7, 'day').toISOString()
    };
    if (selectedInstitution) {
      docData.institutionId = selectedInstitution.id;
    }
    const doc = await PlanningItemsOptionsModel.create(docData);
    await updateEndDates(doc, 'add');
    await fetchPlanningItemsOptionsList(selectedInstitution?.id, 'planning');
    edit(doc);
  };

  const edit = (doc) => {
    setSelectedPlan(doc);
    doRefresh(refresh + 1);
  };

  const onSave = async (newData) => {
    selectedPlan.data = newData;
    await selectedPlan.save();
    await updateEndDates(selectedPlan, 'edit');
    await fetchPlanningItemsOptionsList(selectedInstitution?.id, 'planning');
    doRefresh(0);
    setSelectedPlan(selectedPlan);
    setMarkedId(selectedPlan.id);
    toast.success('Planificación guardada.')
  };

  // Delete

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedDocForDelete(doc);
  };

  const confirmedDelete = async () => {
    await selectedDocForDelete.delete();
    await updateEndDates(selectedDocForDelete, 'delete');
    await fetchPlanningItemsOptionsList(selectedInstitution?.id, 'planning');
    setShowDeleteConfirmation(false);
    setSelectedDocForDelete(null);
    toast.error('Se ha eliminado el documento');
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedDocForDelete(null);
  };

  // Clone

  const clone = async (doc) => {
    const PlanningItemsOptionsModel = ModuleModel(config.modules.cart.planningItemsOptionsEntitySlug);
    doc.data.startDate = dayjs().utc().add(7, 'day').toISOString();
    const docData = { ...doc.data };
    delete docData.id;
    delete docData.num;
    const newDoc = await PlanningItemsOptionsModel.create(docData);
    await updateEndDates(newDoc, 'add');
    await fetchPlanningItemsOptionsList();
    edit(newDoc);
  };

  const updateEndDates = async (currentDoc, action) => {
    // actualizar el endDate del
      // plan recientemente modificado
      // en todo caso el plan inmediantamente anterior
      // si es "editar" y si startDate cambió
        // actualizar el endDate del plan inmediantamente anterior al startDate antiguo
  };

  const handleClone = (doc) => {
    setShowCloneConfirmation(true);
    setSelectedDocForClone(doc);
  };

  const confirmedClone = async () => {
    await clone(selectedDocForClone);
    toast.success('Se ha duplicado el documento');
    setShowCloneConfirmation(false);
  };

  const cancelClone = () => {
    setShowCloneConfirmation(false);
    setSelectedDocForClone(null);
  };

  const getOldDocs = async () => {
    await cartData.fetchOldPlanningDocs();
    setShowOldDocs(true);
  };

  const ListItems = ({ items }) => {
    return (
      <div className="px-2 border border-gray-200 rounded-md">
        {items?.map((item, index) => (
          <div className={`
            flex flex-row place-content-between items-center
            py-2 border-b border-gray-200 last:border-none 
            ${markedPlanId === item.id ? "text-brand-red" : "text-gray-800"}
          `} key={item.id}>
            <div className={`text-sm font-semibold`}>
              # {item.data?.num}
            </div>
            <div className="text-center">
              <div className="">
                <span className="text-xs text-gray-400 uppercase">Desde</span>
                <span className="ml-1.5 text-sm">{dayjs(item.data?.startDate).utc().format('ll')}</span>
              </div>
              {item.data.institutionId ? (
                <div className="text-sm font-semibold text-gray-700">
                  {institutionsDocsById[item.data.institutionId].data.name}
                </div>
              ) : (
                <div className="text-sm font-semibold text-gray-400">
                  GENERAL
                </div>
              )}
              {item.data.isLimit ? (
                <div className="text-xs font-semibold text-red-600">
                  FECHA MÁXIMA
                </div>
              ) : null}
            </div>
            <div className="flex flex-row gap-2">
              <ActionsDropdown classes={{ actionsPopover: 'rounded-md overflow-hidden shadow-xl' }}>
                <IonItem color="secondary" size="small" button onClick={() => edit(item)}>
                  Editar
                </IonItem>
                <IonItem color="medium" size="small" button onClick={() => handleClone(item)}>
                  Duplicar
                </IonItem>
                <IonItem color="primary" size="small" button onClick={() => handleDelete(item)}>
                  Eliminar
                </IonItem>
              </ActionsDropdown>
            </div>
          </div>
        ))}
      </div>
    )
  };

  return (
    <LayoutAdmin 
      history={history} 
      defaultHref={`/admin`}
      title="Menú por días"
      breadcrumbs={[{
        url: '/admin',
        title: 'Panel'
      }, {
        title: 'Planificar opciones de menú'
      }]}
    >
      {/* Ranges List */}
      <div className="ion-padding">
        {(cartData.loading) ? (
          <div className="pt-10 flex place-content-center content-center items-center">
            <BadgeLoading size="md" />
          </div>
        ) : (<>
          <div className="flex flex-row place-content-between items-center">
            <BtnLoading label="Agregar" onClickAsync={add} className="px-2 py-1 hover:scale-105 bg-brand-yellow/80" colorClass="text-black" />

            <div className="flex flex-row gap-3 items-center">
              {selectedInstitution ? (<>
                <div className="font-semibold text-gray-500">{selectedInstitution.data.name}</div>
                <button type="button" onClick={() => setSelectedInstitution(null)} className="w-5 h-5 rounded-full bg-gray-500 text-gray-100 font-normal text-center relative">
                  <div className="relative -mt-0.5">x</div>
                </button>
              </>) : null}
              
              <ActionsDropdown classes={{actionsPopover: '!w-[310px] shadow-xl'}}>
                <div className="p-2 border border-gray-300 rounded-md">
                  <InstitutionSelector title="Seleccionar Institución" {...{ selectedInstitution, setSelectedInstitution }} />
                </div>
              </ActionsDropdown>
            </div>
          </div>

          {planning?.active?.length && (<>
            <h2 className="mb-2 mt-4 text-lg font-semibold text-brand-red">Actual</h2>
            <ListItems items={planning?.active} />
          </>) || null}

          {planning?.future?.length && (<>
            <h2 className="mb-2 mt-4 text-lg font-semibold text-amber-500">Siguientes</h2>
            <ListItems items={planning?.future} />
          </>) || null}

          {showOldDocs ? (
            planning?.past?.length && (<>
              <h2 className="mb-2 mt-4 text-lg font-semibold text-gray-600">Anteriores</h2>
              <ListItems items={planning?.past} />
            </>) || null
          ) : (
            planning?.active.length || planning?.future.length ? (
              <div className="py-10">
                <BtnLoading label="Mostrar anteriores" onClickAsync={getOldDocs} className="mx-auto px-2 py-1 hover:scale-105 bg-gray-600" colorClass="text-white" />
              </div>
            ) : null
          )}
        </>)}
      </div>
      
      {/* Selector de items a incluirse en el range */}
      <IonDrawer refresh={refresh} panelKey={'pane-main'} configGetter={panelConfig}>
        {(selectedPlan && institutionsDocsById) ? (
          <CartItemForPlanning {...{...cartData, selectedPlan, setSelectedPlan, institutionsDocsById, onSave}} />
        ) : null}
      </IonDrawer>

      {showDeleteConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas eliminar este elemento?"
          onConfirm={confirmedDelete}
          onCancel={cancelDelete}
          confirmClass='bg-brand-red text-white'
        />
      )}
      {showCloneConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas Duplicar este elemento?"
          onConfirm={confirmedClone}
          onCancel={cancelClone}
        />
      )}
    </LayoutAdmin>
  );
};