import { useState } from "react";


export default function BtnLoading({ 
  label,
  icon,
  onClick,
  onClickAsync,
  className,
  colorClass = 'text-gray-300',
  classNameDisabled,
  size = 'sm',
  disabled = false,
  onClickDisabled
 }) {
  const [loading, setLoading] = useState(false);

  const onClickWrapper = async () => {
    if (disabled) {
      onClickDisabled && onClickDisabled();
      return;
    }
    if (onClick) {
      onClick();
    } 
    else if (!loading) {
       setLoading(true);
       await onClickAsync();
       setLoading(false);
     }
  };

  return (
    <button className={`
      flex flex-row items-center flex-nowrap gap-2 shadow-md rounded
      ${size === 'sm' ? 'text-sm' : ''}
      ${ disabled ? (classNameDisabled || className) : className || 'px-1 p-1'}
      ${colorClass}
    `}
    disabled={disabled || loading}
    onClick={onClickWrapper}>
      {(loading || icon) ? (
        <div className="w-fit text-center">
          {loading ? (
            <svg className={`animate-spin ${colorClass} ${size === 'sm' ? 'h-4 w-4' : 'h-6 w-6'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : icon}
        </div>
      ) : null}
      <div>
        {label || null}
      </div>
    </button>
  );
}