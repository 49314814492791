import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, doc, setDoc, getDoc, getDocs, addDoc, updateDoc, writeBatch, deleteDoc, deleteField, onSnapshot, GeoPoint, startAt, endAt, limit } from "firebase/firestore";
import geofire from 'geofire-common';
import { db } from "../firebase";
import config from "../config";
import { replaceUndefinedAndNaNWithNull } from './utils';

export default class Model {
  static collectionName;
  static db = db;

  id;
  data;

  constructor(data) {
    if (typeof data === 'string') {
      this.collectionName = data;
      return this;
    }
    this.id = data.id;
    this.data = data;
    return this;
  }

  ////////////////////////////////////////////////////////
  // Static methods
  ////////////////////////////////////////////////////////

  static extend(collectionName) {
    function extendRecursively(BaseClass) {
      return class ExtendedModel extends BaseClass {
        static collectionName = collectionName;
  
        constructor(data) {
          super(data);
        }
      };
    }
  
    return extendRecursively(this);
  }  

  static setFirestoreInstance(dbInstance) {
    this.db = dbInstance;
  }

  static getCollectionRef() {
    if (!this.db) {
      throw new Error("Firestore instance not set. Call setFirestoreInstance() before using the model.");
    }
    return collection(this.db, config.env + '-' + this.collectionName);
  }

  static async count() {
    const querySnapshot = await getDocs(this.getCollectionRef());
    return querySnapshot.size;
  }  

  static async findById(id) {
    const docRef = doc(this.getCollectionRef(), id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return new this({ id: docSnap.id, ...docSnap.data() });
    }
    return null;
  }

  static async findOneBy(field, value) {
    const querySnapshot = await getDocs(query(this.getCollectionRef(), where(field, '==', value)));
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return new this({ id: doc.id, ...doc.data() });
    }
    return null;
  }

  static async findByName(name) {
    const querySnapshot = await getDocs(query(this.getCollectionRef(), where('name', '==', name)));
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      return new this({ id: doc.id, ...doc.data() });
    }
    return null;
  }

  static async getAll() {
    const querySnapshot = await getDocs(this.getCollectionRef());
    let records = [];
    querySnapshot.forEach((doc) => {
      records.push(new this({ id: doc.id, ...doc.data() }));
    });
    return records;
  }

  static getAllOnSnapshot(cb) {
    const queryRef =  this.getCollectionRef();
    return onSnapshot(queryRef, (querySnapshot) => {
      let records = [];
      querySnapshot.forEach((doc) => {
        records.push(new this({ id: doc.id, ...doc.data() }));
      });
      cb(records);
    });
  }

  static async where(fieldName, operator, value) {
    const q = query(this.getCollectionRef(), where(fieldName, operator, value));
    const querySnapshot = await getDocs(q);
    const models = [];
    querySnapshot.forEach((doc) => {
      models.push(new this({ id: doc.id, ...doc.data() }));
    });
    return models;
  }

  static async whereOne(fieldName, operator, value) {
    const records = await this.where(fieldName, operator, value);
    return records?.length ? records[0] : null;
  }

  static async orderBy(fieldName, direction) {
    const q = query(this.getCollectionRef(), orderBy(fieldName, direction));
    const querySnapshot = await getDocs(q);
    const models = [];
    querySnapshot.forEach((doc) => {
      models.push(new this({ id: doc.id, ...doc.data() }));
    });
    return models;
  }

  /**
   * Sorts an array of documents based on a specified field and field type.
   * Supports sorting in ascending (ASC) or descending (DESC) order.
   * @param {Array} docs - The array of documents to be sorted.
   * @param {string} fieldName - The name of the field to sort by. Append " DESC" or " ASC" for descending or ascending order, respectively.
   * @param {string} fieldType - The type of the field (e.g., "number", "string", "date").
   * @returns {Array} - The sorted array of documents.
   */
  static sortDocs(docs, fieldName, fieldType = 'number') {
    const sortOrder = fieldName.endsWith(' DESC') ? 'DESC' : 'ASC';
    const actualFieldName = fieldName.replace(/( DESC| ASC)$/, '');

    return docs && docs.sort((a, b) => {
      const valueA = a?.data[actualFieldName];
      const valueB = b?.data[actualFieldName];
      // number
      if (fieldType === 'number') {
        const sortA = typeof valueA === 'number' ? valueA : Number.MAX_VALUE;
        const sortB = typeof valueB === 'number' ? valueB : Number.MAX_VALUE;

        if (sortA === 0 && sortB === 0) {
          return 0;
        } else if (sortA === 0) {
          return sortOrder === 'ASC' ? -1 : 1;
        } else if (sortB === 0) {
          return sortOrder === 'ASC' ? 1 : -1;
        }

        return sortOrder === 'ASC' ? sortA - sortB : sortB - sortA;
      } 
      // string || dateString
      else if (fieldType === 'string' || fieldType === 'dateString') {
        const sortA = typeof valueA === 'string' ? valueA : '';
        const sortB = typeof valueB === 'string' ? valueB : '';

        return sortOrder === 'ASC' ? sortA.localeCompare(sortB) : sortB.localeCompare(sortA);
      } 
      // date
      else if (fieldType === 'date') {
        const sortA = valueA instanceof Date ? valueA : new Date(0);
        const sortB = valueB instanceof Date ? valueB : new Date(0);

        return sortOrder === 'ASC' ? sortA - sortB : sortB - sortA;
      } else {
        // Handle other field types or default to no sorting
        return 0;
      }
    });
  }

  static async create(data) {
    if (data.id) {
      return this.createWithId(data, data.id);
    }
    data.createdAt = new Date().toISOString();
    data.modifiedAt = new Date().toISOString();
    const sanitizedData = replaceUndefinedAndNaNWithNull(data);
    const docRef = await addDoc(this.getCollectionRef(), sanitizedData);
    return new this({ id: docRef.id, ...data });
  }

  static async createMany(data) {
    const docsAdded = [];
    for (const docData of data) {
      const newDoc = this.create(docData);
      docsAdded.push(newDoc);
    }
    return docsAdded;
  }

  static async createWithId(data, id) {
    data.createdAt = new Date().toISOString();
    data.modifiedAt = new Date().toISOString();
    const docRef = doc(this.getCollectionRef(), id);
    await setDoc(docRef, data);
    return new this({ id, ...data });
  }  

  static async createOrUpdate(data) {
    if (data instanceof Model) {
      const existingModel = await this.findById(data.id);
      // update
      if (existingModel) {
        data.modifiedAt = new Date().toISOString();
        existingModel.data = { ...existingModel.data, ...data.data };
        await existingModel.save();
        return existingModel;
      } 
      // create
      else {
        data.createdAt = new Date().toISOString();
        data.modifiedAt = new Date().toISOString();
        await data.save();
        return data;
      }
    } else {
      const id = data?.id;
      const existingModel = id ? await this.findById(id) : null;
      // update
      if (existingModel) {
        data.modifiedAt = new Date().toISOString();
        existingModel.data = { ...existingModel.data, ...data };
        await existingModel.save();
        return existingModel;
      } 
      // create
      else {
        data.createdAt = new Date().toISOString();
        data.modifiedAt = new Date().toISOString();
        const newData = { ...data };
        if (id) {
          newData.id = id;
        }
        const newModel = await this.create(newData);
        return newModel;
      }
    }
  }

  /**
   * Filtra los registros de la colección basándose en los atributos y valores especificados.
   * @param {Object} attrValObject - Objeto que contiene los atributos y valores para filtrar los registros.
   * @returns {Array} - Un arreglo de registros filtrados.
   *
   * @example
   * // Filtrar usuarios por edad igual a 30 y estado activo
   * const filteredUsers = await UserModel.filterByAttributes({
   *   // FilterQuery
   *   age: 30,
   *   active: true,
   *   active: "true",
   *   price: { gte: 100, lte: 500, eq: 650 },
   *   status: [ "active", "draft " ],
   *   gps: { lat: '', lng: '', distance: '' },
   * });
   */
  static async filterByAttributes(attrValObject, options) {
    let queryRef = this.getCollectionRef();
    
    Object.entries(attrValObject).forEach(([key, value]) => {
      if (key && value) {
        if (Array.isArray(value)) {
          // Filtrar por valores que estén en el array
          queryRef = query(queryRef, where(key, "array-contains-any", value));
        } 
        // Filtrar por valores booleanos con valor en string
        else if (value === "true" || value === "false" || value === true || value === false) {
          const booleanValue = JSON.parse(value);
          queryRef = query(queryRef, where(key, "==", booleanValue));
        } 
        // Filtrar por rangos (menor o igual, mayor o igual, igual)
        else if (
          typeof value === "object" &&
          ("in" in value || "in-array" in value || "lte" in value || "gte" in value || "equal" in value || "ne" in value)
        ) {    
          if ("in" in value && Array.isArray(value.in)) {
            queryRef = query(queryRef, where(key, "in", value.in));
          }
          if ("in-array" in value && Array.isArray(value['in-array'])) {
            queryRef = query(queryRef, where(key, "array-contains-any", value['in-array']));
          }
          if ("lte" in value) {
            queryRef = query(queryRef, where(key, "<=", value.lte));
          }
          if ("gte" in value) {
            queryRef = query(queryRef, where(key, ">=", value.gte));
          }
          if ("equal" in value) {
            queryRef = query(queryRef, where(key, "==", value.equal));
          }
          if ("ne" in value) {
            queryRef = query(queryRef, where(key, "!=", value.ne));
          }
        } 
        // Filtrar por ubicación 
        // else if (
        //   typeof value === "object" &&
        //   ("lat" in value && "lng" in value && "distance" in value)
        // ) {    
        //   setGeoQuery(key, value, queryRef);
        // } 
        // Filtrar por igualdad exacta
        else {
          queryRef = query(queryRef, where(key, "==", value));
        }
      }
    });
    
    // TODO fix
    // if (options?.limit) {
    //   queryRef = limit(queryRef, options.limit);
    // }
    // if (options?.orderBy?.field) {
    //   // queryRef = query(queryRef, orderBy('createdAt', 'asc'));
    //   queryRef = orderBy(queryRef, options.orderBy.field, options.orderBy.direction || 'asc');
    // }
  
    if (options?.onSnapshot) {
      return onSnapshot(queryRef, (querySnapshot) => {
        const records = [];
        querySnapshot.forEach((doc) => {
          records.push(new this({ id: doc.id, ...doc.data() }));
        });
        options.onSnapshot(records, querySnapshot);
      });
    }

    const querySnapshot = await getDocs(queryRef);
    const records = [];
    querySnapshot.forEach((doc) => {
      records.push(new this({ id: doc.id, ...doc.data() }));
    });

    return records;
  }

  static async saveSort(docsSorted) {
    const collectionRef = this.getCollectionRef();
    const batch = writeBatch(db);
  
    for (let i = 0; i < docsSorted.length; i++) {
      const typeId = docsSorted[i].id;
      const docRef = doc(collectionRef, typeId);
      batch.update(docRef, {
        sort: i,
        modifiedAt: new Date().toISOString()
      });
    }
  
    await batch.commit();
    return docsSorted;
  }

  ////////////////////////////////////////////////////////
  // Instance methods
  ////////////////////////////////////////////////////////


  // update
  async save() {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    this.data.modifiedAt = new Date().toISOString();
    const { data, ...cleanData } = this.data;
    await updateDoc(docRef, replaceUndefinedAndNaNWithNull(cleanData) );
  }

  async delete() {
    this.data.deleted = true;
    this.data.deletedDate = new Date().toISOString();
    await this.save();
  }

  async deleteField(fieldName) {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    await updateDoc(docRef, { [fieldName]: deleteField() });
  }

  async deleteFromDisk() {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    await deleteDoc(docRef);
  }

  clone() {
    return new this.constructor({ id: this.id, ...this.data });
  }

  onSnapshot(callback) {
    const docRef = doc(this.constructor.getCollectionRef(), this.id);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const updatedModel = new this.constructor({ id: docSnap.id, ...docSnap.data() });
        callback(updatedModel);
      } else {
        callback(null);
      }
    });
    return unsubscribe;
  }
}

////////////////////////////////////////////////////////
// Hooks 
////////////////////////////////////////////////////////

export const useStateResults = (Model, where) => {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = async () => {
    try {
      let docs = [];
      if (where) {
        docs = await Model.filterByAttributes(where);
      } else {
        docs = await Model.getAll();
      }
      Model.sortDocs(docs, 'sort');
      setRecords(docs);
    } catch (error) {
      console.log('Error fetching:', error);
    }
  };

  return records;
}

export const useStateSingleResult = ({
  Model, 
  id, // fallback to nameSlug
  nameSlug
}) => {
  const [record, setRecord] = useState(null);
  
  useEffect(() => {
    fetchRecords();
  }, [id]);
  
  const fetchRecords = async () => {
    if (!Model) { return; }
    try {
      let doc;
      if (id) {
        doc = await Model?.findById(id);
      }
      if (!doc || nameSlug) {
        doc =  await Model?.findOneBy('nameSlug', nameSlug || id);
      }
      if (doc) {
        setRecord(doc);
      } else {
        setRecord(new Model({}));
      }
    } catch (error) {
      console.log('Error fetching:', error);
    }
  };

  return record;
}

export const GetOneModel = async (collectionName, docId) => {
  // Create a dynamic extended model class using the collectionName
  const ExtendedModel = Model.extend(collectionName);

  try {
    // Find the document by its ID
    const document = await ExtendedModel.findById(docId);

    if (document) {
      return document;
    } else {
      console.log(`Document not found in collection: ${collectionName}`);
      return null;
    }
  } catch (error) {
    console.log(`Error fetching document in collection: ${collectionName}`, error);
    return null;
  }
};

function setGeoQuery(key, value, queryRef) {
  const { lat, lng, distance } = value;
  
  // Crear un geopunto con las coordenadas proporcionadas
  const center = new GeoPoint(parseFloat(lat), parseFloat(lng));
  
  // Calcular el radio en metros
  const radiusInM = parseInt(distance) * 1000;
  
  const bounds = geofire.geohashQueryBounds(center, radiusInM);
  
  for (const b of bounds) {
    queryRef = query(queryRef, orderBy('geohash'), startAt(b[0]), endAt(b[1]));
  }
  
  return queryRef;
}