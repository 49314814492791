import { useEffect, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { getImageURL, priceFormat } from "../../libs/utils";
import { usePanelSheet } from "../../components/ui/PanelSheet";
import BtnLoading from '../../components/ui/BtnLoading';
import { useCart } from './CartContext';
import config from '../../config';
import { QtyButtons } from './QtyButtons';
import ModalCongrats from '../../components/ui/ModalCongrats';

const cartItemEntitySlug = config.modules.cart.cartItemEntitySlug;
const bagsReversed = config?.modules?.cart?.bagsReversed;

const getBagId = (index, bagsItemsDetails) => {
  return bagsReversed 
    ? bagsItemsDetails.length - 1 - index
    : index;
}

const CardItem = ({ 
  goToBag,
  bagsItemsDetails,
  getBagTotal,
  setItemToBag,
  fetchCartDetailsData,
  getPrice
}) => {
  const getTotalQtyItemsByBag = (bag) => {
    return bag.itemsInBag.reduce((acc, itemsInBag) => {
      return acc + itemsInBag.qty;
    }, 0);
  };

  return (
    <div className="w-full bg-white">
      <div className="z-40 py-1 w-full bg-white fixed shadow-md">
        <h3 className="text-lg font-semibold text-center">
          Detalles del pedido
        </h3>
      </div>

      <div className="pt-14">
        <div className="space-y-6">
          {bagsItemsDetails.map((bag, index) => {
            const bagId = getBagId(index, bagsItemsDetails);
            const bagTotal = getBagTotal(bagId);
            const totalItemsQty = getTotalQtyItemsByBag(bag);
            return (
              <div key={bagId} className="px-2">
                <div className="px-2 flex flex-row place-content-between">
                  <button type='button'
                    className="block cursor-pointer text-left" 
                    onClick={() => goToBag(bagId)}
                  >
                    <h2 className="text-black text-lg font-regular underline uppercase leading-none tracking-wide">
                      {bag && dayjs(bag.date).format('dddd D')}
                    </h2>
                    <h2 className="text-gray-600 text-xs font-regular capitalize leading-none tracking-wide">
                      {bag && dayjs(bag.date).format('MMMM, YYYY')}
                    </h2>
                  </button>
                  <div className="">
                    {totalItemsQty ? (
                      <div className="mr-2 inline-block text-center p-0.5 w-6 h-6 text-sm font-semibold bg-gray-100 text-gray-700 rounded-full">
                        {totalItemsQty}
                      </div>
                    ) : null}
                    Total:
                    <h3 className="inline pl-1 text-red-600 mt-1 text-md font-normal leading-none tracking-wide">
                      {priceFormat(bagTotal || 0)}
                    </h3>
                  </div>
                </div>

                <div className={`px-2 cursor-default ${bagTotal > 0 ? 'mt-6 mb-6' : 'mb-2'}`}>
                  {bag?.populatedItemsInBag?.length ? bag.populatedItemsInBag.map((itemInBag, index) => {
                    return (
                      <div key={index} className="w-full py-2 flex flex-row border-b border-gray-200 text-center grow place-content-between">
                        {/* image xs */}
                        <div className="w-2/5">
                          {itemInBag.itemDoc?.data.images ? (
                            <img src={getImageURL(itemInBag?.itemDoc?.data.images[0], 'xs')}
                              className="h-[140px] w-full object-cover cursor-pointer rounded-md shadow-sm border border-gray-200"
                              onClick={() => goToBag(bagId)}
                            /> 
                          ) : (
                            <div className="h-[140px] w-full object-cover cursor-pointer rounded-md shadow-sm bg-gray-100"></div>
                          )}
                        </div>
                        {/* data */}
                        <div className="w-3/5 pb-1 px-1 space-y-1">
                          <QtyButtons {...{
                            bagId, setItemToBag, itemInBag, 
                            itemId: itemInBag.itemDoc.id,
                            extraItemId: itemInBag?.itemExtraDoc?.id,
                            price: getPrice(bag, itemInBag.itemDoc),
                            onChange: fetchCartDetailsData
                          }} Render={({ QtySelector, Total, RemoveButton }) => (<>
                            <div className="flex flex-col gap-2 place-content-between content-start">

                              <div className="px-1.5 text-left leading-tight">
                                <span className="font-semibold">
                                  {itemInBag.itemDoc?.data.name}
                                  {/* CON Guarnición */}
                                  {itemInBag.itemDoc?.data.withExtra ? (<>
                                    <span className='contents'> con <u>{itemInBag.itemExtraDoc?.data.name}</u></span>
                                  </>) : null }
                                </span>
                                <div className="text-sm text-gray-600">{priceFormat(getPrice(bag, itemInBag?.itemDoc))}</div>
                              </div>

                              <div className="border-t border-gray-200 mx-2"></div>

                              <div className="flex flex-row gap-2">
                                <Total classes={{ container: 'w-1/2', label: 'uppercase text-xs', value: 'text-sm text-gray-600 font-semibold' }} />
                                <div className="w-1/2 place-content-center items-center">
                                  <QtySelector className="w-24 mx-auto" />
                                  <RemoveButton className="text-center" />
                                </div>
                              </div>

                            </div>
                          </>)} />
                          
                        </div>
                      </div>
                    );
                  }) : null}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

const CurrentCartDetails = (props) => {
  const { history } = props;
  const [ bagsItemsDetails, setBagsItemsDetails ] = useState([]);
  const { cartData } = useCart();
  const [ showCongrats, setShowCongrats ] = useState();
  const {
    ItemModel, CategoryModel, TypeModel, 
    items, itemsTypes, itemsCategories,
    bags, getBagById, getBagTotal, isItemInBag, setItemToBag, getItemsOfBag, getAllItems,
    cartDoc, saveNewCart, saveCurrentCart, closeCart,
    totalPriceItems, getPrice
  } = cartData;
  const allItems = getAllItems();
  const { panelRef, paneInstance, isPaneOpen, setIsPaneOpen, openPanel, closePanel } = usePanelSheet(({ windowHeight }) => {
    return {
      parentElement: '#single-pane-container',
      breaks: {
        top: { 
          enabled: true, 
          height: (window.innerHeight || 900) - 20,
          bounce: true
        },
        middle: { enabled: false },
        bottom: { enabled: false },
      },
      events: {
        onDidDismiss: () => {
          setIsPaneOpen(false);
        },
      },
      initialBreak: 'top',
      backdrop: true,
      showDraggable: false,
      dragBy: ['.no-draggable'],
      draggableOver: true,
      bottomClose: false,
      buttonDestroy: true,
    };
  });

  useEffect(() => {
    fetchCartDetailsData();
  }, [bags]);

  const fetchCartDetailsData = () => {
    const populatedBags = [];
    for (const bagId in bags) {
      if (Object.hasOwnProperty.call(bags, bagId)) {
        const populatedItemsInBag = getItemsOfBag(bagId);
        populatedBags.push({
          ...bags[bagId],
          populatedItemsInBag
        });
      }
    }
    setBagsItemsDetails(populatedBags);
  };

  const goToBag = (bagId, params) => {
    props?.goToBag && props.goToBag(bagId, params);
    closePanel();
  };

  const openDetails = () => {
    fetchCartDetailsData();
    openPanel();
  };

  const confirm = async () => {
    try {
      await saveCurrentCart();
      setShowCongrats(true);
    } catch (error) {
      toast(error, { icon: "❌" });
    }
  };

  const redirect = () => {
    closeCart();
    history.push('/admin');
  };

  const TotalPanel = ({ className }) => {
    return (allItems?.length ? (
      <div className={`
        fixed left-0 py-4 w-full bottom-0 bg-white text-center border-t-2 border-brand-red shadow-xl shadow-black
        ${className}
      `}>
        <h3 className="shaked-cascade-1 text-black text-base font-semibold uppercase leading-none tracking-wide">
          total
        </h3>
        <h3 className="mb-3 text-brand-red text-xl font-normal leading-none tracking-wide">
          {priceFormat(totalPriceItems)}
        </h3>

        {!isPaneOpen ? (
          <BtnLoading 
            label="Detalles del pedido"
            onClickAsync={openDetails} 
            withLoading
            colorClass="text-white"
            className="shaked-cascade-2 mx-auto px-6 py-2 bg-gray-600 !text-white text-lg hover:scale-105 shadow-lg rounded-md inline-block"
          />
        ) : (
          <BtnLoading
            label="Confirmar"
            onClickAsync={confirm} 
            withLoading
            colorClass="text-white"
            className="shaked-cascade-2 mx-auto px-6 py-2 bg-brand-red !text-white text-lg hover:scale-105 shadow-lg rounded-md inline-block"
          />
        )}
      </div>
    ) : null);
  };


  return (<>
    {/* Congrats modal */}
    {showCongrats ? (
      <ModalCongrats
        onConfirm={redirect}
        title="Buen provecho!"
        subtitle="Pedido registrado, tu órden se ha entregado a la cocina, te avisaremos cada día cuando tus platos estén en camino."
        confirmLabel="Listo"
      />
    ) : null}

    {/* Confirmation pane */}
    <TotalPanel key={1} className="z-40 lg:relative lg:w-full lg:shadow-xl lg:rounded-lg lg:border lg:bg-gray-200" />
    <TotalPanel key={2} className={`z-[60]`} />

    {/* Detalles del pedido */}
    <div id="single-pane-container" className='cart-details'>
      <div ref={panelRef} className=" w-full bg-white">

        <CardItem {...{
          goToBag,
          bagsItemsDetails,
          getBagTotal,
          setItemToBag,
          fetchCartDetailsData,
          getPrice
        }} />
      
      </div>
    </div>

  </>)
};

export default CurrentCartDetails;