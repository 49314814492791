import { RoutePlanningItemsOptions } from './RoutePlanningItemsOptions';
import entities from './entities.json';
import { DocsFilter } from './DocsFilter';


let {
  gastronomicProducts, 
  cartItemCategories, 
  cartItemTypes, 
  gastronomicPlanningItemsOptions, 
  institutions
} = entities;

const UnderListItemTitleForGastronomicProducts = ({ ViewData }) => (<>
  <ViewData field="itemType" classes={{ fieldContainer: 'text-xs uppercase font-semibold text-gray-800' }} />
  <ViewData field="itemCategory" classes={{ fieldContainer: 'text-xs uppercase font-semibold text-gray-400' }} />
</>);

const getCrudSettingsTypes = () => {
  return {
    showBtnMove: true,
    reorder: true
  };
}

export default function ({ setPermissions }) {
  return {
    name: 'Gastronomic Daily Cart',
    slug: 'gastronomicDailyCart',

    permissions: [
      setPermissions({ slug: 'gastronomicProducts', label: 'Productos', type: 'coded' }),
      setPermissions({ slug: 'cartItemCategories', label: 'Categorías de productos', type: 'coded' }),
      setPermissions({ slug: 'cartItemTypes', label: 'Tipos de productos', type: 'coded' }),
      setPermissions({ slug: 'gastronomicPlanningItemsOptions', label: 'Planificaciones de productos', type: 'coded' }),
    ],

    entities: {
      gastronomicProducts,
      cartItemCategories,
      cartItemTypes,
      gastronomicPlanningItemsOptions,
      institutions
    },

    routesAdmin: {
      'gastronomicDailyCart': {
        'planning': {
          permission: { resource: 'gastronomicPlanningItemsOptions', action: 'create' },
          Content: RoutePlanningItemsOptions
        }
      }
    },
    
    components: {
      'gastronomicProducts': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForGastronomicProducts,
          ListHeaderExtraActions: DocsFilter
        }
      },
      'cartItemTypes': {
        crud: {
          settings: getCrudSettingsTypes
        },
      }
    }
  };
};