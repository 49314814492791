export default {
  /////////////////////////////////////////////////////
  //
  // BRAND
  //
  /////////////////////////////////////////////////////
  version: '1.2.1-46',
  projectSlug: 'work-food',
  projectName: 'Work Food | Viandas Empresariales',
  domain: 'work-food.web.app',
  protocol: 'https',
  brand: {
    name: 'Work Food',
    marketType: 'Viandas Empresariales'
  },

  env: 'prod', // window.location.hostname === 'localhost' ? 'dev' : 'prod',

  number: '595984999500',
  numberString: '0984 999 500',
  email: 'contactos.workfood@gmail.com',
  defaultLocationGPS: {
    "lat": -25.3478991,
    "lng": -57.5072891
  },
  locationLink: 'https://maps.app.goo.gl/kcK4Hv6QdFGBrXLPA',
  instagram: 'https://www.instagram.com/',
  facebook: 'https://es-la.facebook.com/',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=py.com.workfood',

  currency: 'PYG',
  phoneCountry: 'py',
  timezone: "America/Asuncion",
  
  // youtube video tutorial embed id
  embedId: null, 
  
  /////////////////////////////////////////////////////
  //
  // PLATFORM
  //
  /////////////////////////////////////////////////////

  // reCaptcha
  reCaptchaSiteKey: "6Lc4rnYoAAAAAJqX2ILBjmCPe_hLVqTQeQ4DMQaB",
  // Maps
  googleMapApiKey: "AIzaSyABI8mQrYqfCdHNeZG_oME7o39n5LFycho",
  // Storage
  imgFolder: 'productsImages',
  getImgPrefix: function (fileName) { return `https://firebasestorage.googleapis.com/v0/b/${this.projectSlug}.appspot.com/o/${this.imgFolder}%2F${fileName}?alt=media` },
  imageDimensions: [
    { width: 2000, height: 2000, suffix: '' },   // full
    { width: 1300, height: 1300, suffix: '-xl' }, // xl
    { width: 800, height: 800, suffix: '-md' },   // md
    { width: 300, height: 300, suffix: '-xs' },   // xs
  ],
  // Filter url
  urlSeparator: '---',

  /////////////////////////////////////////////////////
  //
  // Modules
  //
  /////////////////////////////////////////////////////
  modules: {
    panel: {
      panelSystemVersionEntitySlug: 'systemVersions',
    },

    user: {
      usersEntitySlug: "usersProfiles",
      userCustomerRoleSlug: "customer"
    },

    cart: {
      cartsEntitySlug: 'carts',
      cartItemEntitySlug: 'gastronomicProducts',
      cartItemEntityId: 'BmnoczZReFQGSOjoCWW4',
      cartItemCategoriesEntitySlug: 'cartItemCategories', // TODO rename to ItemCategories
      cartItemTypesEntitySlug: 'cartItemTypes', // TODO rename to ItemTypes 
      withExtraItemTypeId: '7DHJ3fADieojSfG1y6nB', // Guarnición
      mainItemTypeId: 'qREEhE8SKuCLDWOSyg6z', // menú del día, antes: '3LBH52ihywdO6El1NwoM', // Platos

      planningItemsOptionsEntitySlug: 'gastronomicPlanningItemsOptions', // gastronomicDailyCart
      // cart cards stack
      bagsMaxQty: 15,
      maxRepetitiveDays: 30,
      bagsReversed: false,
      maxToleranceForOldPlanning: 20,
    },

    gastronomicDailyCart: {}
  },


  // Cart [ legacy ] 
  getURLprefix: function () { return `${this.protocol}://${this.domain}` },
  getProductLink: function (id) { return `${this.getURLprefix()}/product/${id}` },
  getCartLink: function (id) { return `${this.getURLprefix()}/cart/${id}` },
  getWhatsAppText: function (id) { return `Hola! este es mi enlace de pedido ${this.getCartLink(id)}` },
  whatsAppTextBTN: function () { return `Hola ${this.brand.name}!` },
}